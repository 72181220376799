<template>
  <v-dialog
    v-model="show"
    width="400"
    persistent
    :dark="$dark.get()"
    @click:outside="closeModal()"
  >
    <v-form>
      <v-card class="text-center">
        <v-card-text
          class="mb-6 pt-8"
          style="font-size: 26px; line-height: 100%"
        >
          Вы точно хотите совершить действие удаления?
        </v-card-text>
        <v-text-field
          v-if="isDeleteReason"
          v-model="deleteReasonText"
          style="max-width: 90%; margin: 0 auto"
          label="Причина удаления"
        ></v-text-field>
        <slot name="body"></slot>
        <v-card-actions class="d-flex justify-center pb-6">
          <v-btn class="mr-12 text-capitalize" @click="closeModal()"
            >Отмена</v-btn
          >
          <v-btn class="text-capitalize" color="error" @click="deleteItem()">{{
            deleteButtonText
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    isDeleteReason: {
      type: Boolean,
      default: false,
    },
    deleteButtonText: {
      type: String,
      default: "Удалить",
    },
  },
  data() {
    return {
      deleteReasonText: "",
    };
  },
  methods: {
    deleteItem() {
      if (this.isDeleteReason) {
        if (this.deleteReasonText) {
          this.$emit("delete-modal", this.deleteReasonText);
          this.deleteReasonText = "";
        } else {
          this.$notify({
            group: "app",
            type: "error",
            title: "Ошибка",
            text: "Укажите причину удаления",
          });
        }
      } else {
        this.$emit("delete-modal");
      }
    },
    closeModal() {
      this.deleteReasonText = "";
      this.$emit("close-modal");
    },
  },
};
</script>
