import ShowMessage from "@/Functions/message";
// функция для скачивания файлов которые хранятся в папке uploads, либо если блоб файл, много где стала нужна, поэтому вынес в отдельную

export default function downloadFile(file, blobFileName) {
  let fileArgument;
  if (file?.fileName) {
    fileArgument = file.fileName;
  } else {
    fileArgument = file;
  }
  if (fileArgument.length !== 0) {
    let url;
    const link = document.createElement("a");
    // если передано имя документа, значит это блобфайл
    if (blobFileName) {
      url = URL.createObjectURL(fileArgument);
      link.download = blobFileName;
    } else {
      let baseURL = process.env.VUE_APP_FILE_URL;
      url = baseURL + "/uploads/" + fileArgument;
      link.download = fileArgument;
    }
    link.href = url;

    link.click();
    URL.revokeObjectURL(link.href);
  } else {
    ShowMessage("Не удалось скачать отчет");
  }
}
