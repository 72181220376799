<template>
  <v-card class="container" :dark="$dark.get()">
    <div class="report">
      <v-btn color="primary" @click="downloadDayReport()"
        >Отчет за день <v-icon right>mdi-tray-arrow-down</v-icon></v-btn
      >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="reportDay"
            label="дата"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            hide-details
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="reportDay"
          no-title
          scrollable
          :max="new Date().toISOString()"
          @change="menu = false"
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="report">
      <v-btn color="primary" @click="downloadClientsReport()"
        >Отчет по клиентам <v-icon right>mdi-tray-arrow-down</v-icon></v-btn
      >
      <v-text-field
        v-model.number="clientsInput.daysBefore"
        :rules="[$validate.required]"
        label="Количество дней"
        type="number"
        hint="Через это количество дней будет создаваться отчет"
        required
        clearable
      ></v-text-field>
      <v-select
        v-model="clientsInput.isForClientWithoutOrders"
        label="Аудитория"
        :items="audience"
        item-text="label"
        item-value="value"
      ></v-select>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import downloadFile from "@/Functions/downloadFile";
export default {
  data() {
    return {
      reportDay: new Date().toISOString().substring(0, 10),
      menu: false,
      clientsInput: {
        isForClientWithoutOrders: true,
        daysBefore: 7,
      },
      audience: [
        { label: "Не заказывали", value: true },
        { label: "Заказывали давно", value: false },
      ],
    };
  },
  methods: {
    ...mapActions({
      getDayReport: "PushNotifications/GET_NOTIFY_REPORT",
      getClientsReport: "PushNotifications/GET_NOTIFY_REPORT_CLIENTS",
    }),
    close() {
      this.$emit("close-modal");
    },
    async downloadDayReport() {
      let body = {
        date: this.reportDay,
      };
      this.$emit("load");
      let file = await this.getDayReport(body);
      if (file.length > 0) {
        downloadFile(file + ".xlsx");
        this.$emit("stop-load");
      } else {
        this.$notify({
          group: "app",
          type: "warn",
          title: "Ошибка",
          text: "Не удалось скачать отчет",
        });
        this.$emit("stop-load");
      }
      this.$emit("close-modal");
    },
    async downloadClientsReport() {
      this.$emit("load");
      let file = await this.getClientsReport(this.clientsInput);
      if (file.length > 0) {
        downloadFile(file + ".xlsx");
        this.$emit("stop-load");
      } else {
        this.$notify({
          group: "app",
          type: "warn",
          title: "Ошибка",
          text: "Не удалось скачать отчет",
        });
        this.$emit("stop-load");
      }
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-around;
}
.report {
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 50%;
  gap: 10px;
}
@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
  }
  .report {
    width: 100%;
    padding: 0;
  }
}
</style>
