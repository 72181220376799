<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <div class="custom-card">
      <div class="custom-card__title header">
        <p>Push уведомления и СМС</p>
        <div class="custom-card__btns">
          <v-btn color="warning" @click="reportModal = true">
            Отчеты
            <v-icon right>mdi-tray-arrow-down</v-icon>
          </v-btn>
          <v-btn color="primary" @click="modal = true">
            Добавить
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="custom-card__container">
        <div class="custom-table">
          <div class="head">
            <div
              v-for="item in items.head"
              :key="item.id"
              class="head__item"
              :class="item.id === 1 && 'big'"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-for="item in items.body" :key="item.id" class="value">
            <div class="value__item big">
              {{ item.pushTitle ? item.pushTitle : item.smsText }}
            </div>
            <div class="value__item">
              {{ item.isOn ? "Активное" : "Выключено" }}
            </div>
            <div class="value__item">
              <v-btn icon color="primary" @click="openInfoModal(item)">
                <v-icon>mdi-information</v-icon>
              </v-btn>
              <v-btn icon color="primary" @click="openModalToRedact(item)">
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
              <v-btn icon color="primary" @click="deleteNotify(item.id)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" activator="parent" width="50%">
      <v-card class="modal" :dark="$dark.get()">
        <v-form
          ref="form"
          @submit.prevent="isRedacting ? changeNotify() : addNewNotify()"
        >
          <v-text-field
            v-model.trim="redactingObj.pushTitle"
            :rules="[$validate.required]"
            label="Push заголовок"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.trim="redactingObj.pushBody"
            :rules="[$validate.required]"
            label="Push текст"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.trim="redactingObj.smsText"
            :rules="[$validate.required]"
            label="CMC текст"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.number="redactingObj.daysBefore"
            :rules="[$validate.required]"
            label="Количество дней"
            type="number"
            hint="Через это количество дней будут приходить уведомления"
            required
            clearable
          ></v-text-field>
          <v-select
            v-model.number="redactingObj.isForClientWithoutOrders"
            label="Аудитория"
            :items="audience"
            item-text="label"
            item-value="value"
          ></v-select>
          <v-select
            v-model="redactingObj.isOn"
            label="Состояние"
            :items="status"
            item-text="label"
            item-value="value"
          ></v-select>
          <v-card-actions class="flex justify-end">
            <v-btn right @click="modal = false">Отмена</v-btn>
            <v-btn color="primary" type="submit" right>{{
              isRedacting ? "Изменить" : "Добавить"
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoModal" activator="parent" width="50%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-text class="modal__text">
          <p class="modal__title">Детальная информация</p>
          <div class="modal__text_info">
            <div class="modal__item">
              <span>Текст заголовка: </span>{{ redactingObj.pushTitle }}
            </div>
            <div class="modal__item">
              <span>Текст уведомления: </span> {{ redactingObj.pushBody }}
            </div>
            <div class="modal__item">
              <span>Текст СМС: </span> {{ redactingObj.smsText }}
            </div>
            <div class="modal__item">
              <span>Статус: </span>
              {{ redactingObj.isOn ? "Активно" : "Выключено" }}
            </div>
            <div class="modal__item">
              <span>Количество дней: </span> {{ redactingObj.daysBefore }}
            </div>
            <div class="modal__item">
              <span>Аудитория рассылки: </span>
              {{
                redactingObj.isForClientWithoutOrders
                  ? "Те кто никогда не делал заказы"
                  : "Те кто делали заказ давно"
              }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="flex justify-end">
          <v-btn right @click="infoModal = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteNotify()"
    />
    <v-dialog v-model="reportModal" activator="parent" width="50%">
      <v-card class="modal" :dark="$dark.get()">
        <NotifyReports
          @load="loading = true"
          @stop-load="loading = false"
          @close-modal="reportModal = false"
        ></NotifyReports>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "../components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import NotifyReports from "@/components/NotifyReports";

export default {
  components: {
    loader,
    DeleteDialog,
    NotifyReports,
  },
  data() {
    return {
      loading: false,
      allNotify: [],
      modal: false,
      infoModal: false,
      reportModal: false,
      show: false,
      newTagTitle: "",
      redactingTagId: null,
      isRedacting: false,
      showDeleted: false,
      responseObj: null,
      redactingObj: {
        daysBefore: 0,
        isForClientWithoutOrders: true,
        isOn: false,
        pushBody: "",
        pushTitle: "",
        settingName: "default",
        smsText: "",
      },
      audience: [
        { label: "Не заказывали", value: true },
        { label: "Заказывали давно", value: false },
      ],
      status: [
        { label: "Активировано", value: true },
        { label: "Выключено", value: false },
      ],
      items: {
        head: [
          { id: 1, name: "Заголовок" },
          { id: 2, name: "Статус" },
          { id: 3, name: "Управление" },
        ],
        body: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      STATE: "PushNotifications/STATE",
    }),
  },
  watch: {
    modal(newVal) {
      if (!newVal) {
        this.isRedacting = false;
        this.$refs.form.resetValidation();
        this.redactingObj = { ...this.responseObj };
      }
    },
    infoModal(newVal) {
      if (!newVal) {
        this.redactingObj = { ...this.responseObj };
      }
    },
  },
  mounted() {
    this.setValues();
    this.responseObj = { ...this.redactingObj };
  },
  methods: {
    ...mapActions({
      getAllNotify: "PushNotifications/GET_ALL_NOTIFY",
      deleteNotifyAction: "PushNotifications/DELETE_NOTIFY",
      updateNotify: "PushNotifications/UPDATE_NOTIFY",
      addNotify: "PushNotifications/ADD_NOTIFY",
      getReport: "PushNotifications/GET_NOTIFY_REPORT",
    }),
    //Подгружает все уведомления
    async setValues() {
      this.loading = true;
      await this.getAllNotify();
      if (this.STATE.allNotify.length > 0) {
        this.items.body = this.STATE.allNotify;
      } else {
        this.$notify({
          group: "app",
          type: "warn",
          title: "Ошибка",
          text: "Данные не загрузились",
        });
      }
      this.loading = false;
    },
    //Открывает модалку с данными из выбранного типа
    openModalToRedact(typeObj) {
      this.redactingObj = { ...typeObj };
      this.isRedacting = true;
      this.modal = true;
    },
    async deleteNotify(id) {
      if (id) {
        this.deletedId = id;
      } else {
        this.loading = true;
        let response = await this.deleteNotifyAction(this.deletedId);
        if (response?.type === "error") {
          this.$notify({
            group: "app",
            type: "error",
            title: "Ошибка",
            text: response.data.message,
          });
        }
        this.deletedId = 0;
        await this.setValues();
        this.loading = false;
      }
      this.show = !this.show;
    },
    //Изменяет данные о типе курьера
    async changeNotify() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let response = await this.updateNotify(this.redactingObj);
        if (response?.type === "error") {
          this.$notify({
            group: "app",
            type: "error",
            title: "Ошибка",
            text: response.data.message,
          });
        }
        this.modal = false;
        await this.setValues();
        this.loading = false;
      }
    },
    async addNewNotify() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let response = await this.addNotify(this.redactingObj);
        if (response?.type === "error") {
          this.$notify({
            group: "app",
            type: "error",
            title: "Ошибка",
            text: response.data.message,
          });
        }
        this.modal = false;
        await this.setValues();
        this.loading = false;
      }
    },
    openInfoModal(pushObj) {
      this.redactingObj = pushObj;
      this.infoModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  padding: 40px;
  position: relative;
  &__btns {
    display: flex;
    gap: 10px;
  }
  &__title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }
  .custom-card__container {
    .custom-table {
      .head {
        display: flex;
        justify-content: space-between;
        &__item {
          text-align: left;
          font-weight: 500;
          width: 12%;
          text-align: center;
        }
        .big {
          width: 75%;
          text-align: left;
        }
      }
      .value {
        display: flex;
        justify-content: space-between;
        &__item {
          text-align: left;
          margin-top: 20px;
          width: 12%;
          text-align: center;
        }
        .big {
          width: 75%;
          text-align: left;
        }
        &__btn {
          width: 100%;
        }
      }
    }
  }
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &__item {
    padding-top: 10px;
    span {
      color: black;
      font-weight: bold;
    }
  }
}
@media screen and (max-width: 700px) {
  .value {
    .big {
      max-width: 20%;
    }
  }
  .head {
    .big {
      max-width: 20%;
    }
  }
}
</style>
